// import axios from 'axios'
import Axios from 'axios';
import { setupCache, buildStorage } from "axios-cache-interceptor";
import localforage from 'localforage';
import memoryDriver from 'localforage-memoryStorageDriver';
import each from 'lodash/each';
import map from 'lodash/map';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
const base = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});
async function configure() {
  // Register the custom `memoryDriver` to `localforage`
  await localforage.defineDriver(memoryDriver);

  // Create `localforage` instance
  const forageStore = localforage.createInstance({
    // List of drivers used
    driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE, memoryDriver._driver],
    // Prefix all storage keys to prevent conflicts
    name: 'imcp'
  });
  window.store = forageStore;
  return base.defaults.cache ? base : setupCache(base, {
    storage: buildStorage({
      set: (key, value) => forageStore.setItem(key, value),
      remove: key => forageStore.removeItem(key),
      find: key => forageStore.getItem(key)
    })
  });
}
export default {
  namespaced: true,
  state: {},
  mutations: {
    SET_LOOKUP(state, {
      lookupName,
      value
    }) {
      state[lookupName] = value;
    }
  },
  actions: {
    fetchLookups({
      commit
    }) {
      let urls = [{
        name: "disabilities",
        url: '/global/lookups/disabilities'
      }, {
        name: "ethnicities",
        url: '/global/lookups/ethnicities'
      }, {
        name: "unions",
        url: '/global/lookups/unions'
      }, {
        name: "educational_institutions",
        url: '/global/lookups/educational_institutions'
      }, {
        name: "countries",
        url: '/global/lookups/countries'
      }, {
        name: "ofo_codes",
        url: '/global/lookups/ofo_codes'
      }, {
        name: "nqf_levels",
        url: '/global/lookups/nqf_levels'
      }, {
        name: "education_levels",
        url: '/global/lookups/education_levels'
      }, {
        name: "municipalities",
        url: '/global/lookups/municipalities'
      }, {
        name: "shareClasses",
        url: '/global/lookups/share_classes'
      }, {
        name: "occupational_levels",
        url: '/global/lookups/occupation_levels'
      }, {
        name: "SETAClassifications",
        url: '/global/lookups/seta_classifications'
      }, {
        name: "industrySectors",
        url: '/global/lookups/industry_sectors'
      }, {
        name: "bargaining_councils",
        url: '/global/lookups/bargaining_councils'
      }, {
        name: 'business_types',
        url: '/global/lookups/business_types'
      }, {
        name: 'subscription_plans',
        url: '/global/lookups/plans'
      }, {
        name: 'userTitles',
        url: '/global/lookups/user_titles'
      }, {
        name: 'userRoles',
        url: '/global/lookups/user_roles'
      }, {
        name: 'employmentStatuses',
        url: '/global/lookups/employment_statuses'
      }];
      configure().then(api => {
        const promises = map(urls, ({
          url,
          name
        }) => api.get(url, {
          cache: {
            ttl: 60 * 60 * 1000
          },
          id: name
        }));
        Promise.allSettled(promises).then(results => {
          each(results, result => {
            if (result.status === 'fulfilled') {
              let {
                name
              } = find(urls, {
                url: result.value?.config?.url
              });
              commit('SET_LOOKUP', {
                lookupName: name,
                value: result.value?.data
              });
            }
          });
        });
      });
    }
  },
  getters: {
    disabilities: state => state.disabilities,
    ethnicities: state => state.ethnicities,
    unions: state => state.unions,
    educational_institutions: state => state.educational_institutions,
    countries: state => state.countries,
    ofo_codes: state => state.ofo_codes,
    employmentStatuses: state => state.employmentStatuses,
    nqf_levels: state => state.nqf_levels,
    education_levels: state => state.education_levels,
    municipalities: state => state.municipalities,
    shareClasses: state => state.shareClasses,
    occupational_levels: state => state.occupational_levels,
    SETAClassifications: state => state.SETAClassifications,
    industrySectors: state => state.industrySectors,
    bargaining_councils: state => state.bargaining_councils,
    business_types: state => state.business_types,
    subscription_plans: state => state.subscription_plans,
    userTitles: state => state.userTitles,
    userRoles: state => state.userRoles,
    activeRoles: state => filter(state.userRoles, {
      hidden: false,
      editable: true
    }),
    assignableRoles: state => orderBy(filter(state.userRoles, {
      assignable: true
    }), ['priority'], ['asc'])
  }
};