import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, mergeProps as _mergeProps, normalizeClass as _normalizeClass, Teleport as _Teleport, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "relative w-screen mx-4 flex justify-center",
  ref: "modalInner"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_XMarkIcon = _resolveComponent("XMarkIcon");
  return $setup.done ? (_openBlock(), _createBlock(_Teleport, {
    key: 0,
    to: ".base-modal"
  }, [_createElementVNode("div", {
    onClick: _cache[1] || (_cache[1] = _withModifiers($event => !$props.persistant && _ctx.$emit('update:modelValue', false), ["stop", "self"])),
    class: _normalizeClass(["modal w-screen transition-all duration-300 ease-in-out", {
      'modal-open': $props.modelValue ?? $setup.flag
    }]),
    ref: "modal",
    style: _normalizeStyle({
      zIndex: `${$props.zIndex ?? 1000}`
    })
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _mergeProps(_ctx.$attrs, {
    style: {
      maxWidth: (($props.width * 1 > $setup.screenInnerWidth ? $setup.screenInnerWidth : $props.width * 1) ?? $setup.screenInnerWidth) + 'px'
    },
    class: "relative bg-white rounded-md w-full"
  }), [$props.closable ? (_openBlock(), _createElementBlock("button", {
    key: 0,
    onClick: _cache[0] || (_cache[0] = _withModifiers($event => {
      _ctx.$emit('update:modelValue', false);
      $setup.flag = false;
      _ctx.$emit('close-modal', false);
    }, ["stop"])),
    class: "absolute rounded-full bg-primary-400 p-1 transition-all duration-200 ease-in-out top-2 right-1 z-50"
  }, [_createVNode(_component_XMarkIcon, {
    class: "w-5 h-5 fill-white"
  })])) : _createCommentVNode("", true), _createElementVNode("div", {
    class: "overflow-y-auto rounded",
    style: _normalizeStyle([{
      maxHeight: $setup.maxHeight + 'px',
      minHeight: $setup.isNil($props.minHeight) ? 'auto' : $props.minHeight + 'px'
    }, {
      "height": "100%"
    }])
  }, [_renderSlot(_ctx.$slots, "default")], 4)], 16)], 512)], 6)])) : _createCommentVNode("", true);
}