import { isNil } from "lodash";
import { nextTick, onMounted, ref } from "vue";
import { reactive } from "vue";
import { computed } from "vue";
export default {
  name: "BaseModal",
  inheritAttrs: false,
  props: {
    modelValue: {},
    value: {
      type: Boolean
    },
    persistant: {
      type: Boolean
    },
    closable: {
      type: Boolean
    },
    width: {
      type: Number
    },
    height: {
      type: [Number, String]
    },
    minHeight: {
      type: [Number, String]
    },
    zIndex: {
      type: Number
    }
  },
  emits: ["update:modelValue", "close-modal"],
  setup(props, {
    emit
  }) {
    const done = ref(false);
    const flag = ref(true);
    const screenInnerHeight = ref(window.innerHeight - 100);
    const screenInnerWidth = ref(window.innerWidth);
    const heightAdjustment = reactive({
      heightValue: props.height,
      screenHeight: screenInnerHeight
    });
    const maxHeight = computed(() => {
      return (heightAdjustment.heightValue * 1 > heightAdjustment.screenHeight ? heightAdjustment.screenHeight : heightAdjustment.heightValue * 1) ?? heightAdjustment.screenHeight;
    });
    const modalInner = ref(null);
    done.value = true;
    onMounted(async () => {
      await nextTick();
      modalInner.value?.addEventListener("click", e => {
        if (e.currentTarget === e.target && !props.persistant) {
          emit("update:modelValue", false);
          flag.value = false;
          emit("close-modal", false);
        }
      });
    });
    return {
      done,
      flag,
      screenInnerHeight,
      screenInnerWidth,
      modalInner,
      isNil,
      maxHeight
    };
  }
};